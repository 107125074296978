import React from 'react';

const Login = React.lazy(() => import('./Demo/LoginPage'));
const HomePage = React.lazy(() => import('./Demo/HomePage'));
const ResetPasswordPage = React.lazy(() => import('./Demo/ResetPassword'));

const route = [
    { path: '/login', exact: true, name: 'Login Page', component: Login },
    { path: '/', exact: true, name: 'Home Page', component: HomePage },
    { path: '/update-password/:id', exact: true, name: 'Home Page', component: ResetPasswordPage },
];

export default route;